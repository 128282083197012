/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
$primary: #ff5b4a;
$secondary: #2d1855;
$default: #0e1029;

/**
	Button
*/
.btn {
	border-radius: 20px;
	&:disabled {
		background-color: $default !important;
		border-color: $default !important;
	}
	&.btn-primary, &.btn-outline-primary {
		&:hover {
			background-color: $primary !important;
			border-color: $primary !important;
			box-shadow: 0 8px 25px -8px $primary !important;
		}
	}
	&.btn-outline-primary:hover {
		color: white !important;
	}
}

/**
	Input
*/
[dir] .form-control {
	border-radius: 20px;
	border-color: #000000;
}
[dir] .dark-layout input.form-control:not(:focus) {
	border-color: white;
}

.rating-scale-section{
	.rating-scale {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 1;
		margin-right: 65px;
		.active-radio {
			width: 40px;
			height: 40px;
			border: 1px dashed #03030C;
			border-radius: 50%;
			top:-7px;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			display: none;
			position: absolute;
		}
		.active-radio.isActive {
			display: flex;
		}
		@media (max-width: 544px) {
			margin-right: 30px;			
		}
	}
	.rating-scale:last-child{
			margin-right: 0px;
	}
}

.rating-scale input[type='radio'] {
	width: 24px;
	height: 24px;
}

.rating-scale input[type='radio']:hover::after {
	background-color: rgba(255, 91, 74, 0.12);
}

.rating-scale input[type='radio']::after {
	width: 24px;
	height: 24px;
	border-radius: 15px;
	position: relative;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 1px solid #FF5B4A;
	background-color: #fff;
	top: -1px;
	left: -1px;
	cursor: pointer;
}

.experience-cicle::after, .education-cicle::after {
	content: '';
    height: 60px;
    width: 1px;
    position: absolute;
    border-right: dashed 1px var(--bs-primary);
    transform: translateY(113%);
	@media (max-width: 767px) {
		content: "";
		height: 194px;
		width: 0px;
		position: absolute;
		border-right: dashed 1px var(--bs-primary);
		transform: translateY(65%);
	}
}

// video-player
.react-video-controls{
	background-color: rgb(48, 73, 74);
}
.react-video-controls .progress-wrap .react-video-marker {
	margin-top: -3px;
	border: 5px solid #000;
	border-radius: 50px;
}

.react-video-controls .progress-wrap progress::-webkit-progress-value {
	background: #ff5b4a;
}

.img-grid img{
	max-width: 700px;
}